<template>
  <div class="sucess-wrapper">
    <b-container>
      <b-row>
        <b-col lg="4" class="mx-auto mt-5">
          <CardFram>
            <template #body>
              <b-img fluid :src="require('@/assets/imgs/success.svg')"></b-img>
            </template>
            <template #footer>
              <h2 class="submit-title font-bold">
                {{ $t("heading.submit_success") }}
              </h2>
            </template>
          </CardFram>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Sucess",
  components: {
    CardFram: () => import("@/components/CardFram")
  },
  mounted() {
    setTimeout(() => {
      this.$emit("close_overlay");
    }, 1500);
  }
};
</script>
